import { states } from '~/utils/vehicle'

export type Roles =
  | 'admin'
  | 'facilities'
  | 'swapper'
  | 'pickup'
  | 'guardian'
  | 'mechanic'
  | 'refurb'
  | 'missing'
  | 'support'

export type Permission = {
  [permission: string]: boolean | number[]
}

export const UserRoles: { [K in Roles]: Permission } = {
  admin: {
    canManageUsers: true,
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: true,
    canCompletePDI: true,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: true,
    canAccessReports: true,
    canAccessVehicles: true,
    canViewVehicleSummary: true,
    canViewJobCards: true,
    accessibleVehicleStates: [0, 2, 4, 5, 6, 8, 10, 12, 14], // All states for admin
  },
  facilities: {
    canSearch: false,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: true,
    canCompletePDI: true,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: false,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2, 5], // 'Active', 'Greenhouse', 'Move'
  },
  swapper: {
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: false,
    canCompletePDI: false,
    canOpenBatteryCompartment: false,
    canSwapBattery: true,
    canAccessMap: true,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2], // 'Active', 'Move'
  },
  pickup: {
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: false,
    canCompletePDI: false,
    canOpenBatteryCompartment: false,
    canSwapBattery: true,
    canAccessMap: true,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2, 5], // 'Active', 'Greenhouse', 'Move'
  },
  guardian: {
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: true,
    canCompletePDI: true,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: true,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2, 12], // 'Active', 'Move', 'Damage Collect'
  },
  mechanic: {
    canSearch: false,
    canChangeState: false,
    canLockUnlock: false,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: false,
    canCompletePDI: false,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: false,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [], // No states for mechanic
  },
  refurb: {
    canSearch: false,
    canChangeState: false,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: false,
    canCompletePDI: false,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: false,
    canAccessReports: false,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0], // Only 'Active'
  },
  missing: {
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: true,
    canCompletePDI: false,
    canOpenBatteryCompartment: true,
    canSwapBattery: true,
    canAccessMap: true,
    canAccessReports: true,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2, 4], // 'Active', 'Move', 'Fuel Low'
  },
  support: {
    canSearch: true,
    canChangeState: true,
    canLockUnlock: true,
    canSwitchLights: true,
    canHonk: true,
    canSetMaxSpeed: true,
    canCompletePDI: false,
    canOpenBatteryCompartment: false,
    canSwapBattery: false,
    canAccessMap: true,
    canAccessReports: true,
    canAccessVehicles: false,
    canViewVehicleSummary: false,
    accessibleVehicleStates: [0, 2, 4, 8], // 'Active', 'Move', 'Fuel Low', 'Support & Community'
  },
}

export function userHasPermission(user: any, permission: string) {
  const group = user?.app_metadata?.group as Roles
  return UserRoles[group]?.[permission] || false
}

export function getAccessibleStates(user: any) {
  const userGroup = user?.app_metadata?.group as Roles

  return states.filter((state) => {
    const accessibleStates = UserRoles[userGroup]?.accessibleVehicleStates
    return (
      Array.isArray(accessibleStates) && accessibleStates.includes(state.id)
    )
  })
}
